define("discourse/plugins/discourse-anonymous-categories/discourse/connectors/category-custom-settings/anonymous-categories", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = (_obj = {
    onChangeSetting(value) {
      this.set("category.custom_fields.force_anonymous_posting", value ? "true" : "false");
    }
  }, (_applyDecoratedDescriptor(_obj, "onChangeSetting", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onChangeSetting"), _obj)), _obj);
});